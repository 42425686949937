import Column from 'components/column';
import Image from 'components/image';
import MatrixFigure from 'components/matrix-figure';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import Employees, { Employee } from 'sections/employees';
import Hero from 'sections/hero';
import Spacing from 'sections/spacing';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import LangSlug from 'components/lang-slug';

import * as styles from './contact-page.module.scss';

type ContactPageProps = {
  data: {
    page: Pick<
      GatsbyTypes.DatoCmsContactPage,
      'title' | 'image' | 'text' | 'categories' | 'seoMetaTags'
    >;
    allDatoCmsPerson: {
      persons: Employee[];
    };
  };
};

const ContactPage: React.FC<ContactPageProps> = ({ data }) => {
  const {
    page,
    allDatoCmsPerson: { persons },
  } = data;

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <LangSlug model={'contact_page'} />
      <Hero backgroundColor={'white'}>
        <Column className={styles.heroContainer}>
          <h1>{page.title}</h1>
        </Column>
      </Hero>
      <Section className={styles.section} backgroundColor={'blue'}>
        <Column className={styles.sectionColumn} xl={6} lg={6} md={6}>
          {page.text && <div dangerouslySetInnerHTML={{ __html: page.text }} />}
        </Column>
        <Column className={styles.sectionColumn} xl={6} lg={6} md={6} sm={6}>
          <div className={styles.sectionImage}>
            <MatrixFigure className={styles.sectionFigure} size={'small'} />
            <Image {...page.image} />
          </div>
        </Column>
      </Section>
      <Spacing backgroundColor={'blue'} />
      {page.categories &&
        page.categories.map(
          (category, index) =>
            category && (
              <Employees
                key={category.id}
                firstElement={index === 0}
                title={category.title as string}
                backgroundColor={'light'}
                employees={persons.filter((p) =>
                  p.category?.map((c) => c && c.id).includes(category.id)
                )}
              />
            )
        )}
    </>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: datoCmsContactPage {
      id: originalId
      seoMetaTags {
        tags
      }
      title
      image {
        ...Image
      }
      text
      categories {
        id: originalId
        title
      }
    }
    allDatoCmsPerson(sort: { fields: lastName, order: ASC }) {
      persons: nodes {
        ...Employee
      }
    }
  }
`;
