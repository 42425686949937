// extracted by mini-css-extract-plugin
export var large = "employees-module--large--eplyO";
export var section = "employees-module--section--inKWD";
export var column = "employees-module--column--WubN9";
export var columnFirst = "employees-module--columnFirst--1h+Dx";
export var employee = "employees-module--employee--Gnq5f";
export var shape = "employees-module--shape--3MpA6";
export var employeeInner = "employees-module--employeeInner--+vNgx";
export var employeeName = "employees-module--employeeName--2tbxj";
export var employeeContent = "employees-module--employeeContent--Usxdu";
export var employeeContentBottomLinks = "employees-module--employeeContentBottomLinks--bGQCJ";
export var separator = "employees-module--separator--uPCzz";
export var employeeImage = "employees-module--employeeImage--I3mWz";
export var employeeImageEmpty = "employees-module--employeeImageEmpty--gTIpu";
export var vCardButton = "employees-module--vCardButton--azx9j";
export var vCardButtonArrow = "employees-module--vCardButtonArrow--61MeB";