import clsx from 'clsx';
import Arrow from 'components/arrow';
import Column from 'components/column';
import Image from 'components/image';
import { EmailLink } from 'components/link';
import Section, { SectionProps } from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import VCard from 'vcard-creator';
import { saveAs } from 'file-saver';

import * as styles from './employees.module.scss';
import MatrixFigure from 'components/matrix-figure';

export type Employee = Pick<
  GatsbyTypes.DatoCmsPerson,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'education'
  | 'title'
  | 'phoneNumber'
  | 'mobileNumber'
  | 'eMail'
  | 'image'
  | 'category'
>;

const VCardButton: React.FC<{ employee: Employee }> = ({ employee }) => {
  const onClickHandle = React.useCallback(async () => {
    const myVCard = new VCard();

    const phone = `${employee.phoneNumber}`.replace(/\D+/gm, '');
    const mobile = `${employee.mobileNumber}`.replace(/\D+/gm, '');

    myVCard
      .addName(employee.lastName, employee.firstName)
      .addJobtitle(`${employee.title}${employee.education ? ', ' + employee.education : ''}`)
      .addPhoneNumber(mobile as unknown as number, 'TEL;TYPE=CELL')
      .addPhoneNumber(phone as unknown as number, 'TEL;TYPE=WORK')
      .addEmail(`${employee.eMail}`, 'EMAIL;TYPE=WORK')
      .addCompany('Inhouse Tech Göteborg AB');

    if (employee.image?.url) {
      const base64Img = await new Promise((res, _) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          const reader = new FileReader();
          reader.onloadend = () => res(reader.result);
          reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', `${employee.image?.url}&w=400&h=400&ar=1&fit=crop&fm=png&q=75`);
        xhr.responseType = 'blob';
        xhr.send();
      });
      myVCard.addPhoto((base64Img as string).replace('data:image/png;base64,', ''), 'PNG');
    }

    const file = new Blob([myVCard.toString()], { type: 'text/vcard;charset=utf-8' });

    saveAs(file, `${employee.firstName}-${employee.lastName}.vcf`);
  }, [employee]);

  return (
    <button onClick={onClickHandle} className={styles.vCardButton}>
      vCard <Arrow dir={'right'} className={styles.vCardButtonArrow} />
    </button>
  );
};

const EmployeeItem: React.FC<Employee> = (employee) => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    const personAnchor = [employee.firstName?.trim(), employee.lastName?.trim()]
      .join('-')
      .replace(' ', '-')
      .toLowerCase();

    return (
      <Column
        id={personAnchor}
        as={'article'}
        className={styles.employee}
        xl={3}
        lg={4}
        md={6}
        sm={6}
      >
        <div className={styles.employeeInner}>
          {employee.image && <Image className={styles.employeeImage} {...employee.image} />}
          {!employee.image && (
            <div className={clsx(styles.employeeImage, styles.employeeImageEmpty)} />
          )}

          {!employee.image && (
            <MatrixFigure
              fig={[
                [' ', 'x'],
                ['x', ' '],
              ]}
              className={styles.shape}
            />
          )}
          <div className={styles.employeeContent}>
            <div>
              <p className={styles.employeeName}>
                {employee.firstName} {employee.lastName}
              </p>
              <p>{employee.title}</p>
              {employee.education && <p>{employee.education}</p>}
            </div>
            <div>
              <p>{employee.phoneNumber}</p>
              <p>{employee.mobileNumber}</p>
              <div className={styles.employeeContentBottomLinks}>
                {employee.eMail && <EmailLink email={employee.eMail} title={t('E-post')} arrow />}
                <VCardButton employee={employee} />
              </div>
            </div>
          </div>
        </div>
      </Column>
    );
  }, [employee, t]);
};

type EmployeesProps = SectionProps<'section'> & {
  title: string;
  firstElement?: boolean;
  employees: Employee[];
};

const Employees: React.FC<EmployeesProps> = ({
  title,
  firstElement = false,
  employees,
  ...props
}) =>
  React.useMemo(() => {
    return (
      <Section
        className={styles.section}
        topBackgroundColor={firstElement ? 'blue' : undefined}
        topBackgroundOffset={firstElement ? '200px' : undefined}
        {...props}
      >
        <Column className={clsx(styles.column, { [styles.columnFirst]: firstElement })}>
          <h2>{title}</h2>
        </Column>
        {employees.map(
          (employee) => employee && <EmployeeItem key={`${title}-${employee.id}`} {...employee} />
        )}
      </Section>
    );
  }, [employees, firstElement, props, title]);

export default Employees;

export const query = graphql`
  fragment Employee on DatoCmsPerson {
    id: originalId
    firstName
    lastName
    title
    education
    phoneNumber
    eMail
    mobileNumber
    category {
      id: originalId
    }
    image {
      isImage
      mimeType
      gatsbyImageData(
        placeholder: DOMINANT_COLOR
        imgixParams: { ar: "1.058", fit: "crop" }
        width: 380
      )
      alt
      url
    }
  }
`;
